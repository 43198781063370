.image-right{
  @media only screen and (min-width: 500px) {
    float: right;
    margin: 12px 0px 12px 24px;
    max-width: 300px;
  }
}

.caption-placeholder{
  @media only screen and (max-width: 501px) {
    margin-bottom: 20px;
  }
}

.centered-figure{
  display: flex;
  flex-direction: column;
  align-items: center;

  img{
    border-radius: 3px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  }

  &.small{
    img{
      @media only screen and (min-width: 500px) {
          width: 400px;
      }
    }
  }

  &.medium{
    img{
      @media only screen and (min-width: 700px) {
          width: 600px;
      }
    }
  }
}


.chat-form {
  margin-bottom: 1em;

  .message-container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    div {
      margin-top: 5px;
      background-color: rgb(29, 29, 29);
      color: white;
      padding: 5px 10px;
      width: fit-content;
      float: right;
      border-radius: 5px;
    }
  }
}

.no-border{
  padding: 10px;
  img {
    box-shadow: none;
  }
}
#video-player{
  margin: 20px 0;
}
